import React, { lazy, useMemo } from 'react';

import { useAppSelector } from 'store/hooks';
import {
  convertRoute,
  convertStaticRoute,
  getActiveLanguages,
} from 'utils/languages';

const PageNav = lazy(() => import('navigations/PageNav'));
const Home = lazy(() => import('pages/Home'));
const SelectKiosk = lazy(() => import('pages/SelectKiosk'));

const useRoutesList = () => {
  const locales = useAppSelector((state) => state.systems.general?.locales);

  const activeLangList = useMemo(
    () => getActiveLanguages(locales),
    [locales],
  );

  const routes = useMemo(
    () => ({
      pages: {
        path: convertRoute(activeLangList, ':slug'),
        element: <PageNav />,
      },
      survey: {
        path: convertStaticRoute('SURVEY', activeLangList, true),
        element: <Home />,
      },
      kiosk: {
        path: convertStaticRoute('KIOSK', activeLangList, true),
        element: <SelectKiosk />,
      },
    }),

    [activeLangList],
  );

  return { routes };
};

export default useRoutesList;
