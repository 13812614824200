import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Slider, { LazyLoadTypes } from 'react-slick';

import useWindowDimensions from 'hooks/useWindowDemensions';
import { useAppSelector } from 'store/hooks';
import { getSlugByTemplateCode } from 'utils/functions';
import { getLangURL } from 'utils/languages';

interface WaitingScreenBlocks {
  information: {
    banners: BannerPageTypes;
  }
}

const WaitingScreen: React.FC<PageTemplateResponseType<WaitingScreenBlocks>> = ({
  blocks,
}) => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const kioskCodeParam = searchParams.get('code');
  const staticPages = useAppSelector((state) => state.systems.staticPages);
  const slugWaitingConfirmPage = getSlugByTemplateCode('WAITING_CONFIRM_PAGE', i18n.language, staticPages) || '';

  const { width } = useWindowDimensions();
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    cssEase: 'ease-in-out',
    pauseOnHover: false,
    lazyLoad: 'ondemand' as LazyLoadTypes,
  };

  const slideList = useMemo(() => {
    if (blocks && blocks.information.banners) {
      const banners = blocks.information.banners.data.reduce((prev: {
        desktop: string[], mobile: string[]
      }, current) => ({
        desktop: prev.desktop.concat(current.data.imageDesktop || ''),
        mobile: prev.mobile.concat(current.data.imageMobile || '')
      }), { desktop: [], mobile: [] });

      if (width >= 1082) {
        return {
          banners: banners.desktop,
          url: `/${getLangURL(i18n.language)}${slugWaitingConfirmPage}${kioskCodeParam ? `/?code=${kioskCodeParam}` : ''}`
        };
      }
      return {
        banners: banners.mobile,
        url: `/${getLangURL(i18n.language)}${slugWaitingConfirmPage}${kioskCodeParam ? `/?code=${kioskCodeParam}` : ''}`
      };
    }

    return {
      banners: [],
      url: `/${getLangURL(i18n.language)}${slugWaitingConfirmPage}${kioskCodeParam ? `/?code=${kioskCodeParam}` : ''}`
    };
  }, [blocks, width, i18n.language, slugWaitingConfirmPage, kioskCodeParam]);

  return (
    <div className="p-waitingScreen">
      <Slider {...settings}>
        {
          slideList.banners.map((item, index) => (
            <div
              key={`p-waitingScreen-${index.toString()}`}
              onClick={() => navigate(slideList.url)}
            >
              <div className="p-waitingScreen_item">
                <img src={item} alt="slider" />
              </div>
            </div>
          ))
        }
      </Slider>
    </div>
  );
};

WaitingScreen.defaultProps = {
};

export default WaitingScreen;
