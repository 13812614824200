import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useLanguage from 'hooks/useLanguage';
import WaitingScreen from 'pages/WaitingScreen';
import { getStaticHomeService } from 'services/navigations';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { savePageData } from 'store/pages';
import { setTranslationCurrentPage } from 'store/systems';

const HomeNav: React.FC = () => {
  const dispatch = useAppDispatch();
  const { i18n } = useTranslation();
  const pages = useAppSelector((state) => state.pages.pages);
  const { handleGoHomePage } = useLanguage();

  const [isOfflineMode, setIsOfflineMode] = useState(false);
  const [isErrorCallAPI, setIsErrorCallAPI] = useState(false);
  const [pageData, setPageData] = useState<PageTemplateResponseType<any>>();

  useEffect(() => {
    const getDataOfflineMode = () => {
      try {
        if (pages
          && pages[i18n.language]
          && pages[i18n.language].length > 0) {
          const data = pages[i18n.language]?.find((
            item: any
          ) => item?.pageData?.templateCode === 'BANNERS_PAGE');

          if (data) {
            setPageData(data);
          } else if (isErrorCallAPI) {
            handleGoHomePage('vi');
          }
        }
      } catch (error) {
        console.log('error get Homepage: ', error);
        setPageData(undefined);
      }
    };
    if (isOfflineMode) {
      getDataOfflineMode();
    }
  }, [isOfflineMode, pages, i18n.language, isErrorCallAPI]);

  useEffect(() => {
    const handlePageData = async () => {
      try {
        const res = await getStaticHomeService();
        setPageData(res);
        setIsOfflineMode(false);
        dispatch(savePageData(res));
        dispatch(setTranslationCurrentPage(res?.translations));
      } catch (e: any) {
        if (e?.response?.status === 500) {
          setIsErrorCallAPI(true);
        }
        setIsOfflineMode(true);
      }
    };
    handlePageData();
  }, [dispatch]);

  return React.createElement<
    PageTemplateResponseType<any>>(WaitingScreen as FunctionComponent, pageData);
};

export default HomeNav;
